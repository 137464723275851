import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"

import InfoWindow from "../components/infoWindow"

//import InfoSection from ""

const more = [
  <div>
    <ul>
      <li> Clean Bedrooms </li>
      <li> Sanitise Bathrooms </li>
      <li> Disinfect the Kitchen </li>
      <li> Dust off Living room </li>
      <li> Wipe Hallways & Staircases </li>
    </ul>
    <p>Contact us to learn more.</p>
  </div>,
  <div>
    <ul>
      <li> Discounts </li>
      <li> Priority booking times</li>
      <li> 24/7 Call-outs </li>
    </ul>
    Contact us for more information.
    <p></p>
  </div>,
  <div>
    <ul>
      <li> Optional Service Report </li>
      <li> Periodic Updates </li>
      <li> 24/7 Call-outs </li>
      <li> and more... </li>
    </ul>
    Contact us to customise the service.
    <p></p>
  </div>,
]

const InspectionReporting = (
  <div>
    <h2> Expert Domestic Cleaning.</h2>
    <i></i>
    <div>
      <p>
        <h4>Forget about domestic chores and cleaning your home. </h4>
        As reputable local cleaners, we can clean your home both one-off and on
        a regular basis as frequently as you like. All you have to do is book
        our domestic cleaning service. Then sit back and enjoy your free time.
      </p>
      <h4>We offer:</h4>
      <ul class="tic">
        <li>One-off, weekly, fortnightly or monthly cleanings available</li>
        <li>Antiviral Sanitisation to kill 99% of germs and viruses</li>
        <li>Custom checklist - you tell us what to clean</li>
        <li>Professionalism you can trust</li>
        <li>Same and next day service to help with your urgent tasks</li>
      </ul>
      <h4>Discount.</h4> Discounts apply to bulk or regular orders. <br />
      <a href="tel:0413231255">Call us for details</a>
    </div>
  </div>
)
const RegularService = (
  <div>
    <h2> What is included in your cleaning service?</h2>
    <p>Our standard Regular Cleaning Package duties include:</p>
    <h4>Throughout the property:</h4>
    <ul>
      <li>
        {" "}
        <li> Vacuuming floors </li>
        <li> Washing hard floors</li>
        <li> General furniture, skirtings & window sill dusting </li>
        <li> Mirrors cleaned</li>
        <li> Bins Emptied</li>
        <li> Laundry sink</li>
        <li> Mirrors cleaned</li>
      </li>
    </ul>
    <h4>Kitchen:</h4>
    <ul>
      <li>
        {" "}
        <li> Bench tops & tile area </li>
        <li> Sink, basin & taps</li>
        <li> Stove top </li>
        <li> Microwave inside & outside</li>
      </li>
    </ul>
    <h4>Bathroom(s) & Toilet(s):</h4>
    <ul>
      <li>
        {" "}
        <li> Toilet(s) – cleaned & sanitised </li>
        <li> Tile areas & vanity units</li>
        <li> Sink basin & taps, bath(s) & taps </li>
        <li> Shower area including shower head, taps & screens</li>
      </li>
    </ul>
  </div>
)
const ExtraMile = (
  <div>
    <h2>24/7 Service</h2>
    <p>
      <h4>Available 24/7: </h4>
      We work 24/7 to cater to all needs.
      <h4> Bond Cleaning: </h4> Do you need professional bond cleaning service
      in South East Queensland? Call us today on{" "}
      <a href="tel:0413231255">0413 231 255</a>.<h4> Affordable: </h4> We offer
      affordable end of tenancy cleaning services for all Brisbane, Gold Coast,
      & Sunshine Coast postcodes and areas
    </p>
  </div>
)

const ChuteUnblocking = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "home-clean-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "home-clean-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "home-clean-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout style={{ height: "80vh" }}>
      <SEO
        description="Moving in or out or simply want a fresh house? Our cleaning staff will professionally clean your house or apartment!"
        lang="en"
        meta=""
        title="Professional Home Cleaning | ChuteOff | Brisbane, Gold Coast, Sunshine Coast"
      />
      <section id="top">
        <InfoWindow
          id="chute-cleaning"
          title={<>HOME CLEANING SERVICES</>}
          subtitle="Once-off or regular cleaning in Brisbane, Gold Coast, and the Sunshine Coast homes!"
          panes={[InspectionReporting, RegularService, ExtraMile]}
          buttons={["CONTACT ON-CALL STAFF", "BOOK SERVICE", "CUSTOM JOB"]}
          button_subtitles={[
            "After every service you receive a FREE personalised service report.",
            "Not sure what you need? Talk to an expert",
            "Request a custom job tailored to your needs",
          ]}
          images={[
            data.image1.childImageSharp.fluid,
            data.image2.childImageSharp.fluid,
            data.image3.childImageSharp.fluid,
          ]}
          more={more}
          expansion_title={[
            "What is involved in a standard professional home clean?",
            "What will I receive as a regular client?",
            "Does ChuteOff offer me any extras?",
          ]}
        />
      </section>
      <Process />
    </Layout>
  )
}

export default ChuteUnblocking
